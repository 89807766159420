import { BILLING_TYPE } from 'constants/billing';
import { useMemo } from 'react';

import { TicketBillingRecord } from 'types/api';

interface TicketBillingRecordsAggregateHookParams {
  billingRecords: TicketBillingRecord[];
}

export default function useTicketBillingRecordsAggregate({
  billingRecords,
}: TicketBillingRecordsAggregateHookParams) {
  const aggregateData = useMemo(() => {
    const totalTime = billingRecords
      .filter(
        (billingRecord) => billingRecord.billing_type === BILLING_TYPE.TIME
      )
      .reduce((a, b) => a + b.amount, 0);

    const totalExpense = billingRecords
      .filter(
        (billingRecord) => billingRecord.billing_type === BILLING_TYPE.EXPENSE
      )
      .reduce((a, b) => a + b.amount, 0);

    return {
      totalExpense,
      totalTime,
    };
  }, [billingRecords]);

  return {
    ...aggregateData,
  };
}
