import {
  MutableRefObject,
  useRef,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
} from 'react';
import { Box, TextInput, Text } from '@mantine/core';

import { BillingRecord, ProjectTicket } from 'types/api';
import { BillingRecordsListProps } from '../types';
import { MODALS } from 'constants/component';

import * as billing from 'utils/billing';

import JobTypeSelect from 'components/common/Select/variants/JobTypeSelect';
import BillableCheckbox from 'components/common/Checkbox/variants/BillableCheckbox';
import UserInput from 'components/common/UserInput';
import ExpenseTypeSelect from 'components/common/Select/variants/ExpenseTypeSelect';
import ProjectTicketSelect from 'components/common/Select/variants/ProjectTicketSelect';

import useApiStore from 'hooks/store/useApiStore';
import useTimeInput from 'hooks/common/useTimeInput';
import useModalStore from 'hooks/store/useModalStore';

import getTicketDisplay from 'helpers/display/getTicketDisplay';
import InstanceUserSelect from 'components/common/Select/variants/InstanceUserSelect';
import CurrencyInput from 'components/common/UserInput/variants/CurrencyInput';

interface EditableItemProps {
  billingRecord: BillingRecord;
  recordKey: keyof BillingRecord;
  editBillingRecord: typeof billing.editBillingRecord;
  variant: BillingRecordsListProps['variant'];
  readonly?: boolean;
}

export default function EditableItem({
  billingRecord,
  recordKey,
  editBillingRecord,
  variant,
  readonly,
}: EditableItemProps) {
  const { invalidate } = useApiStore();
  const { pushModal } = useModalStore();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(billingRecord[recordKey]?.toString());
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const {
    inputValue,
    value: timeValue,
    handleChange,
    handleBlur: handleTimeBlur,
  } = useTimeInput({ defaultValue: billingRecord?.amount });

  const handleSubmit = useCallback(
    async (newValue: any) => {
      await editBillingRecord({
        ticketId: billingRecord.ticket_id,
        billingRecordId: billingRecord?.id as number,
        body: {
          [recordKey]: newValue,
        },
      });
      invalidate('edit-ticket-modal');
      invalidate('list-billing-records-modal');
    },
    [
      billingRecord?.id,
      billingRecord.ticket_id,
      editBillingRecord,
      invalidate,
      recordKey,
    ]
  );

  const handleBlur = useCallback(
    async (e?: ChangeEvent<HTMLInputElement>) => {
      setIsEditing(false);
      let inputValue: string | number | undefined = value;

      if (variant === 'time') {
        if (e) {
          handleTimeBlur?.();
        }

        if (recordKey === 'amount') {
          inputValue = timeValue;
        }
      }

      if (inputValue !== billingRecord[recordKey]) {
        handleSubmit(inputValue!);
      }
    },
    [
      billingRecord,
      handleSubmit,
      handleTimeBlur,
      recordKey,
      timeValue,
      value,
      variant,
    ]
  );

  useEffect(() => {
    if (inputRef.current && isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (
      recordKey === 'amount' &&
      timeValue !== billingRecord.amount &&
      variant === 'time'
    ) {
      handleSubmit(timeValue);
    }

    // eslint-disable-next-line
  }, [timeValue]);

  if (recordKey === 'employee_id') {
    return (
      <Box sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
        <InstanceUserSelect
          variant="avatar"
          defaultValue={billingRecord?.employee?.id}
          onChange={(selected) => {
            const selectedValue = selected.value?.toString();
            setValue(selectedValue);
            handleSubmit(selectedValue);
          }}
        />
      </Box>
    );
  }

  if (recordKey === 'billable') {
    return (
      <Box sx={{ marginTop: 'auto', marginBottom: 'auto', display: 'flex' }}>
        <BillableCheckbox
          sx={{ marginTop: 'auto', marginBottom: 'auto' }}
          defaultChecked={billingRecord?.billable}
          onChange={(e) => {
            handleSubmit(e.target.checked);
          }}
          disabled={readonly}
        />
      </Box>
    );
  }

  if (recordKey === 'ticket') {
    return (
      <Box sx={{ marginTop: 'auto', marginBottom: 'auto', width: '100%' }}>
        {!readonly ? (
          <ProjectTicketSelect
            projectId={billingRecord.ticket?.project_id as number}
            defaultValue={billingRecord?.ticket?.id as number}
            onChange={(selected) => {
              const selectedValue = selected.value?.toString();
              setValue(selectedValue);
              handleSubmit(selectedValue);
            }}
          />
        ) : (
          <Text
            color="blue"
            sx={(theme) => ({
              '&:hover': {
                color: theme.colors.blue[8],
                cursor: 'pointer',
              },
              '&:active': {
                color: theme.colors.blue[9],
              },
            })}
            onClick={() => {
              pushModal(MODALS.EDIT_TICKET_MODAL, {
                ticket: billingRecord.ticket,
                queryKey: 'billing-records',
              });
            }}
          >
            {getTicketDisplay(billingRecord.ticket as ProjectTicket)}
          </Text>
        )}
      </Box>
    );
  }

  if (recordKey === 'amount' && variant === 'time') {
    return !readonly ? (
      <TextInput
        value={inputValue}
        ref={inputRef}
        onBlur={handleBlur}
        onChange={handleChange}
        sx={{ width: '100%', marginTop: 'auto', marginBottom: 'auto' }}
      />
    ) : (
      <Text sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
        {inputValue} HR
      </Text>
    );
  }

  if (recordKey === 'amount' && variant === 'expense') {
    return !readonly ? (
      <CurrencyInput
        value={parseInt(value || '0') / 100}
        onChange={(inputValue) => {
          setValue(`${((inputValue as number) || 0) * 100}`);
        }}
        type={recordKey === 'amount' ? 'number' : 'text'}
        onBlur={handleBlur}
        size="sm"
      />
    ) : (
      <Text>${parseInt(value || '0') / 100}</Text>
    );
  }

  if (recordKey === 'job_type' && variant === 'time') {
    return !readonly ? (
      <JobTypeSelect
        defaultValue={billingRecord.job_type}
        onChange={(selected) => {
          handleSubmit(selected.value);
        }}
      />
    ) : (
      <Text sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
        {billingRecord.job_type}
      </Text>
    );
  }

  if (recordKey === 'job_type' && variant === 'expense') {
    return !readonly ? (
      <ExpenseTypeSelect
        defaultValue={billingRecord.job_type}
        onChange={(selected) => {
          handleSubmit(selected.value);
        }}
      />
    ) : (
      <Text sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
        {billingRecord.job_type}
      </Text>
    );
  }

  if (readonly) {
    return (
      <Text sx={{ marginTop: 'auto', marginBottom: 'auto' }}>{value}</Text>
    );
  }

  return (
    <TextInput
      sx={{ flex: 1, marginTop: 'auto', marginBottom: 'auto' }}
      ref={inputRef}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
    />
  );
}
