import { Box, Sx, Text } from '@mantine/core';
import { ReactNode } from 'react';

interface ContentSectionProps {
  children: ReactNode;
  title: string;
  titleSx?: Sx;
}

export default function ContentSection({
  children,
  title,
  titleSx,
}: ContentSectionProps) {
  return (
    <Box>
      <Box
        sx={(theme) => ({
          borderBottom: '1px solid',
          borderColor: theme.white,
          paddingBottom: theme.spacing.xs,
          marginBottom: theme.spacing.xs,
        })}
      >
        <Text weight="bold" align="center" size="xl" sx={titleSx}>
          {title}
        </Text>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}
