import { Tabs, Box, createStyles, rem } from '@mantine/core';
import { IconMessageCircle, IconActivity } from '@tabler/icons-react';

import TicketActivityList from 'components/common/ActivityList/TicketActivityList';
import Comments from 'components/common/Comments';
import useProjectPageStore from 'hooks/store/useProjectPageStore';

import { ProjectTicket } from 'types/api';

interface TabbedContentProps {
  ticket?: ProjectTicket;
}

const TABS = {
  COMMENTS: 'comments',
  HISTORY: 'history',
};

const useStyles = createStyles(() => ({
  tab: {
    padding: rem(8),
    fontSize: rem(16),
  },
}));

export default function TabbedContent({ ticket }: TabbedContentProps) {
  const { classes } = useStyles();
  const { ruleErrors } = useProjectPageStore();

  return (
    <Box
      sx={(theme) => ({
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        width: '100%',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
      })}
    >
      <Tabs
        variant="outline"
        defaultValue={TABS.COMMENTS}
        sx={(theme) => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: theme.spacing.xl,
          width: '100%',
        })}
      >
        <Tabs.List sx={(theme) => ({ marginBottom: theme.spacing.xs })}>
          <Tabs.Tab
            value={TABS.COMMENTS}
            className={classes.tab}
            icon={<IconMessageCircle size="1rem" />}
          >
            Comments
          </Tabs.Tab>
          <Tabs.Tab
            value={TABS.HISTORY}
            icon={<IconActivity size="1rem" />}
            className={classes.tab}
          >
            History
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={TABS.COMMENTS} pt="xs" sx={{ height: '100%' }}>
          <Comments
            ticketId={ticket?.id?.toString()}
            highlight={ruleErrors.includes('comment_count')}
          />
        </Tabs.Panel>

        <Tabs.Panel value={TABS.HISTORY} pt="xs" sx={{ height: '100%' }}>
          <TicketActivityList ticketId={ticket?.id} />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
}
