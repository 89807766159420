import {
  createStyles,
  Text,
  Avatar,
  TypographyStylesProvider,
  Paper,
  Box,
  Grid,
  Group,
} from '@mantine/core';
import { useMemo } from 'react';
import { Interweave } from 'interweave';
import { notifications } from '@mantine/notifications';

import { TicketComment } from 'types/api';

import AttachmentPreview from 'components/common/Attachment/Preview';

import getInitials from 'helpers/display/getInitials';
import getUserFullName from 'helpers/display/getUserFullName';
import * as ticketUtils from 'utils/ticket';
import useUsers from 'hooks/users/useUsers';
import useDownloadStore from 'hooks/store/useDownloadStore';
import useAuthUser from 'hooks/users/useAuthUser';

const useStyles = createStyles((theme) => ({
  comment: {},

  body: {
    fontSize: theme.fontSizes.sm,
  },

  content: {
    '& p': {
      marginBottom: '0px !important',
    },
  },
}));

interface CommentItemProps {
  comment: TicketComment;
}

export default function CommentItem({ comment }: CommentItemProps) {
  const { classes } = useStyles();
  const { data } = useUsers();
  const { pushDownload } = useDownloadStore();
  const { formatUserTimezoneRelative } = useAuthUser();

  const commentUser = useMemo(() => {
    return data?.users?.find((user) => user.id === comment.user_id);
  }, [comment, data]);

  const fullName = useMemo(() => {
    const name = getUserFullName(commentUser);

    if (name) return name;

    if (comment?.user_id) {
      return `User ID $${comment.user_id}`;
    }

    return 'Unknown User';
  }, [comment.user_id, commentUser]);

  return (
    <Paper radius="md" className={classes.comment}>
      <Grid gutter={8}>
        <Grid.Col sm={2} md={1} sx={{ paddingBottom: 0 }}>
          <Avatar radius="xl">
            {getInitials({
              fullName,
            })}
          </Avatar>
        </Grid.Col>
        <Grid.Col sm={10} md={11} sx={{ paddingBottom: 0 }}>
          <Box>
            <Text fz="sm">{fullName}</Text>
            {comment.datetime_updated && (
              <Text fz="xs" c="dimmed">
                {formatUserTimezoneRelative(new Date(comment.datetime_updated))}
              </Text>
            )}
          </Box>
        </Grid.Col>
        <Grid.Col sm={2} md={1} />
        <Grid.Col sm={10} md={11} sx={{ paddingTop: 0 }}>
          <TypographyStylesProvider className={classes.body}>
            <Interweave className={classes.content} content={comment.content} />
          </TypographyStylesProvider>
          {Boolean(comment?.attachments?.length) && (
            <>
              <Group
                sx={(theme) => ({
                  paddingTop: theme.spacing.xs,
                  paddingBottom: theme.spacing.xs,
                  borderBottomLeftRadius: theme.radius.sm,
                  borderBottomRightRadius: theme.radius.sm,
                })}
              >
                {comment?.attachments
                  ?.filter((attachment) => attachment)
                  ?.map(
                    (attachment) => new File([], attachment.split('/').at(-1))
                  )
                  ?.map((attachment) => (
                    <AttachmentPreview
                      key={attachment.name}
                      file={attachment}
                      onDownload={() => {
                        pushDownload({
                          filename: attachment.name,
                          download: async () => {
                            const foundFilename = comment?.attachments?.find(
                              (a) => a.includes(attachment.name)
                            );

                            if (foundFilename) {
                              await ticketUtils.downloadTicketAttachment(
                                comment?.ticket_id as number,
                                foundFilename
                              );
                              notifications.show({
                                message:
                                  'File has been successfully downloaded.',
                                variant: 'info',
                              });
                            }
                          },
                        });
                      }}
                    />
                  ))}
              </Group>
            </>
          )}
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
