import { Card, Box, createStyles, rem } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { BILLING_TYPE } from 'constants/billing';
import { BillingType } from 'types/app';

import { MODALS } from 'constants/component';
import getHourDisplay from 'helpers/display/getHourDisplay';
import { ProjectTicket } from 'types/api';
import { AddBillingRecordModalProps } from '../../AddBillingRecordModal/types';

import useModalStore from 'hooks/store/useModalStore';

const useStyles = createStyles((theme) => ({
  iconButton: {
    borderRight: '1px solid',
    borderColor: theme.colors.gray[4],
    display: 'flex',
    padding: rem(8),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
    '&:active': {
      backgroundColor: theme.colors.gray[2],
    },
  },
  displayText: {
    padding: rem(8),
    color: theme.black,
    fontWeight: 'bold',
    flex: 1,
    fontSize: theme.fontSizes.lg,
    textAlign: 'center',
    '&:hover': {
      color: theme.colors.blue,
      cursor: 'pointer',
    },
  },
  suffix: {
    borderColor: theme.colors.gray[4],
    padding: rem(8),
    color: theme.black,
    fontSize: theme.fontSizes.lg,
    fontWeight: 'bold',
    width: '48px',
    textAlign: 'right',
  },
}));

interface BillingTypeDisplayProps {
  billingType: BillingType;
  amount: number;
  onClick?: () => void;
  ticket?: ProjectTicket;
  highlight?: boolean;
}

function getBillingTypeSuffix(billingType: BillingType) {
  if (billingType === BILLING_TYPE.EXPENSE) {
    return 'USD';
  }

  return 'HR';
}

function getBillingAmountDisplay(
  billingType: BillingType,
  amount: number
): string {
  if (billingType === BILLING_TYPE.TIME) {
    return getHourDisplay(amount);
  }

  return (amount / 100).toFixed(2);
}

export default function BillingTypeDisplay({
  billingType,
  amount,
  onClick,
  ticket,
  highlight,
}: BillingTypeDisplayProps) {
  const { classes } = useStyles();
  const { pushModal } = useModalStore();

  const handlePlusClick = () => {
    const modalId =
      billingType === BILLING_TYPE.EXPENSE
        ? MODALS.LIST_EXPENSES_MODAL
        : MODALS.ADD_BILLING_RECORD_MODAL;

    pushModal<AddBillingRecordModalProps>(modalId, {
      ticket,
      showAdd: true,
    });
  };

  return (
    <Card
      sx={(theme) => ({
        padding: '0 !important',
        backgroundColor: highlight ? theme.colors.yellow[1] : undefined,
      })}
    >
      <Box sx={{ display: 'flex' }}>
        <Box className={classes.iconButton} onClick={handlePlusClick}>
          <IconPlus />
        </Box>
        <Box className={classes.displayText} onClick={onClick}>
          {getBillingAmountDisplay(billingType, amount)}
        </Box>
        <Box className={classes.suffix}>
          {getBillingTypeSuffix(billingType)}
        </Box>
      </Box>
    </Card>
  );
}
