import {
  ActionIcon,
  Box,
  Checkbox,
  Grid,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { useFormikContext } from 'formik';
import { notifications } from '@mantine/notifications';

import { ProjectTicket } from 'types/api';

import MainContentMetadata from './Metadata';
import useProjectPageStore from 'hooks/store/useProjectPageStore';

interface MainContentTitleProps {
  ticket?: ProjectTicket;
  title: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

export default function MainContentTitle({
  title,
  ticket,
  isEditing,
  setIsEditing,
}: MainContentTitleProps) {
  const { values, handleChange, setFieldValue, submitForm } =
    useFormikContext<Partial<ProjectTicket>>();
  const { moveTicketById } = useProjectPageStore();

  return (
    <Box
      sx={(theme) => ({
        position: 'relative',
        marginBottom: theme.spacing.xs,
      })}
    >
      <Grid columns={12}>
        {!isEditing && (
          <Grid.Col xs={1}>
            <Box>
              <Tooltip
                label={values.is_done ? 'Un-mark as Done' : 'Mark as Done'}
              >
                <Checkbox
                  radius="xl"
                  color="green"
                  checked={values.is_done}
                  onChange={() => {
                    try {
                      setFieldValue('is_done', !values.is_done);
                      moveTicketById(
                        ticket?.project_id?.toString() as string,
                        ticket?.status as string,
                        ticket?.id as number,
                        'done'
                      );
                      submitForm();
                    } catch (err) {
                      const error = err as Error;
                      notifications.show({
                        message: error.message,
                        color: 'red',
                      });
                    }
                  }}
                />
              </Tooltip>
            </Box>
          </Grid.Col>
        )}
        <Grid.Col xs={isEditing ? 12 : 10}>
          {isEditing ? (
            <TextInput
              name="title"
              value={values.title}
              onChange={handleChange}
            />
          ) : (
            <>
              <Text align="center" weight="bold" sx={{ lineHeight: '100%' }}>
                {title}
              </Text>
              <MainContentMetadata ticket={ticket} isEditing={isEditing} />
            </>
          )}
        </Grid.Col>
        {!isEditing && (
          <Grid.Col xs={1}>
            {!isEditing && (
              <ActionIcon
                variant="subtle"
                color="blue"
                sx={{ float: 'right' }}
                onClick={() => setIsEditing(!isEditing)}
              >
                <IconEdit />
              </ActionIcon>
            )}
          </Grid.Col>
        )}
      </Grid>
    </Box>
  );
}
