import { create } from 'zustand';
import { arrayMoveImmutable } from 'array-move';

import type { TicketGroup as TicketGroupType } from 'pages/Project/components/TicketGroup/types';
import { ProjectTicket } from 'types/api';
import { RULE_LABELS } from 'constants/ticket';
import { isEmpty } from 'lodash';

interface TicketGroupMap {
  [id: string]: TicketGroupType;
}

interface ProjectPageFilters {
  blocked?: 'only' | 'none' | 'all';
}

interface ProjectPageStoreState {
  projectsMap: {
    [projectId: string]: {
      ticketGroups: TicketGroupMap;
    };
  };
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
  filters: ProjectPageFilters;
  setFilters: (filters: ProjectPageFilters) => void;
  setTicketGroups: (projectId: string, ticketGroup: TicketGroupMap) => void;
  getTicketGroups: (projectId: string) => TicketGroupMap;
  rules: { [projectId: string]: { [status: string]: string[] } };
  setRules: (projectId: string, rules: { [status: string]: string[] }) => void;
  ruleErrors: string[];
  modalOpen: boolean;
  setModalOpen: (show: boolean) => void;
  moveTicketById: (
    projectId: string,
    sourceStatus: string,
    ticketId: number,
    destStatus: string,
    destIndex?: number
  ) => void;
  moveTicket: (
    projectId: string,
    sourceStatus: string,
    sourceIndex: number,
    destStatus: string,
    destIndex: number
  ) => void;
  preventTicketMove: (
    ticket: ProjectTicket,
    destStatus: string
  ) => string | null;
  clearErrors: () => void;
}

const useProjectPageStore = create<ProjectPageStoreState>((set, get) => ({
  projectsMap: {},
  searchValue: '',
  setSearchValue: (searchValue) => {
    set((state) => ({ ...state, searchValue }));
  },
  filters: {},
  setFilters: (filters: ProjectPageFilters) => {
    set((state) => ({ ...state, filters }));
  },
  rules: {},
  ruleErrors: [],
  modalOpen: false,
  setModalOpen: (modalOpen: boolean) => {
    set((state) => ({ ...state, modalOpen }));
  },
  clearErrors: () => {
    set((state) => ({ ...state, ruleErrors: [] }));
  },
  setRules: (projectId: string, rules: { [status: string]: string[] }) => {
    set((state) => ({ ...state, rules: { [projectId]: rules } }));
  },
  setTicketGroups: (projectId: string, ticketGroup: TicketGroupMap) => {
    const state = get();
    const projectsMap = state.projectsMap;
    const projectMap = projectsMap[projectId] || {};

    projectMap.ticketGroups = ticketGroup;

    set((state) => ({
      ...state,
      projectsMap: { ...projectsMap, [projectId]: projectMap },
    }));
  },
  getTicketGroups: (projectId: string) => {
    const state = get();
    const searchValue = state.searchValue?.toLowerCase();
    const ticketGroups = state.projectsMap[projectId]?.ticketGroups || {};
    const filters = state.filters;

    if (!searchValue && isEmpty(filters)) {
      return ticketGroups;
    }

    const newTicketGroups: TicketGroupMap = {};

    Object.entries(ticketGroups).forEach(([key, ticketGroup]) => {
      const items = ticketGroup.items.filter((item) => {
        let include = item.title.toLowerCase().includes(searchValue || '');

        if (include) {
          if (filters.blocked === 'none') {
            include = !item.blocked;
          }

          if (filters.blocked === 'only') {
            include = Boolean(item.blocked);
          }
        }

        return include;
      });

      if (items.length > 0) {
        newTicketGroups[key] = {
          ...ticketGroup,
          items,
        };
      }
    });

    return newTicketGroups;
  },
  moveTicketById(
    projectId: string,
    sourceStatus: string,
    ticketId: number,
    destStatus: string,
    destIndex?: number
  ) {
    const state = get();
    const projectMap = state.projectsMap[projectId];

    if (!projectMap) return;

    const sourceGroup = projectMap.ticketGroups[`dnd-list-${sourceStatus}`];
    const destGroup = projectMap.ticketGroups[`dnd-list-${destStatus}`];
    const sourceIndex = sourceGroup.items.findIndex(
      (item) => item.id === ticketId
    );

    state.moveTicket(
      projectId,
      sourceStatus,
      sourceIndex,
      destStatus,
      destIndex ?? destGroup.items.length
    );
  },
  moveTicket(
    projectId: string,
    sourceStatus: string,
    sourceIndex: number,
    destStatus: string,
    destIndex?: number
  ) {
    const state = get();
    const projectsMap = state.projectsMap;
    const projectMap = state.projectsMap[projectId];

    if (!projectMap) return;

    const sourceGroup = projectMap.ticketGroups[`dnd-list-${sourceStatus}`];
    const destGroup = projectMap.ticketGroups[`dnd-list-${destStatus}`];
    const isSame = sourceGroup?.id === destGroup?.id;

    destIndex = destIndex ?? destGroup.items.length;

    if (!sourceGroup || !destGroup) return;
    if (isSame && sourceIndex === destIndex) {
      return;
    }

    const ticket = sourceGroup.items[sourceIndex];
    const errorMessage = state.preventTicketMove(ticket, destStatus);
    if (errorMessage) {
      throw new Error(errorMessage);
    }

    let destItems = destGroup.items.slice();

    if (isSame && destIndex !== undefined) {
      destItems = arrayMoveImmutable(destItems, sourceIndex, destIndex);
    } else {
      destItems = destGroup.items.slice(0, destIndex);
      destItems.push(ticket, ...destGroup.items.slice(destIndex));
    }

    // update positions locally
    destItems = destItems.map((item, index) => ({
      ...item,
      position: index,
    }));

    if (!isSame) {
      sourceGroup.items = sourceGroup.items
        .filter((t) => t.id !== ticket.id)
        .map((item, index) => ({
          ...item,
          position: index,
        }));
    }

    destGroup.items = destItems;

    set((state) => ({
      ...state,
      projectsMap: { ...projectsMap, [projectId]: projectMap },
    }));
  },
  preventTicketMove: (ticket: ProjectTicket, destStatus: string) => {
    const state = get();
    const rules = state.rules;
    const foundRules = rules?.[`${ticket.project_id}`]?.[destStatus];
    let output = null;

    if (!foundRules || !foundRules?.length) {
      return output;
    }

    const ruleErrors: string[] = [];

    foundRules.forEach((rule) => {
      if (!ticket[rule as keyof ProjectTicket]) {
        ruleErrors.push(rule);
      }
    });

    if (ruleErrors.length > 0) {
      const ruleLabels = ruleErrors.map((rule) => {
        let label = RULE_LABELS[rule as keyof typeof RULE_LABELS];

        if (!label) {
          label = rule.replace('_', ' ');
        }

        return label;
      });
      output = `Cannot move to status ${destStatus} until ticket has ${ruleLabels.join(
        ', '
      )}.`;
    }

    if (state.modalOpen) {
      set((state) => ({ ...state, ruleErrors }));
      setTimeout(() => {
        set((state) => ({ ...state, ruleErrors: [] }));
      }, 4000);
    }

    return output;
  },
}));

export default useProjectPageStore;
