import axios from './axios';
import constructQueryPath from 'helpers/api/constructQueryPath';
import { ENDPOINTS } from 'constants/api';
import {
  AvailabilityRecord,
  InstanceAlert,
  InstanceNotification,
  InstanceUser,
} from 'types/api';

export interface AvailabilityRecordBody {
  instance_user_id: number;
  description: string;
  date_start: string;
  date_end: string;
}

export type AvailabilityRecordUpdateBody = Pick<
  AvailabilityRecord,
  'status' | 'status_reason' | 'date_start' | 'date_end' | 'description'
>;

export type EditUserBody = Pick<
  InstanceUser,
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'timezone'
  | 'manager_id'
  | 'is_admin'
  | 'min_expected_work'
>;

export async function addAvailabilityRecord({
  body,
}: {
  body: Partial<AvailabilityRecordBody>;
}) {
  await axios.put(constructQueryPath(ENDPOINTS.AVAILABILITY_RECORDS), body);
}

export async function updateAvailabilityRecord({
  recordId,
  body,
}: {
  recordId: string | number;
  body: Partial<AvailabilityRecordUpdateBody>;
}) {
  await axios.patch(
    constructQueryPath(ENDPOINTS.AVAILABILITY_RECORD, {
      recordId: `${recordId}`,
    }),
    body
  );
}

export async function getInstanceUsers() {
  return axios.get(constructQueryPath(ENDPOINTS.USERS));
}

export type NotificationBody = Pick<
  InstanceNotification,
  'is_read' | 'is_archived'
>;

export async function inviteUserByEmail({ email }: { email: string }) {
  return axios.post(constructQueryPath(ENDPOINTS.USERS), {
    email,
    action: 'invite',
  });
}

export async function updateNotification({
  notifId,
  body,
}: {
  notifId: number;
  body: Partial<NotificationBody>;
}) {
  return axios.patch(
    constructQueryPath(ENDPOINTS.NOTIFICATION, {
      notificationId: `${notifId}`,
    }),
    body
  );
}

export async function updateAlert({
  userId,
  alertId,
  body,
}: {
  userId: number;
  alertId: number;
  body: Partial<InstanceAlert>;
}) {
  return axios.patch(
    constructQueryPath(ENDPOINTS.USER_ALERT, {
      userId: userId.toString(),
      alertId: alertId.toString(),
    }),
    body
  );
}

export async function updateUser({
  userId,
  body,
}: {
  userId: string;
  body: Partial<InstanceUser>;
}) {
  return axios.patch(constructQueryPath(ENDPOINTS.USER, { userId }), body);
}

export interface RearrangeUserProjectsParams {
  userId: string;
  projectIds: number[];
}

export async function rearrangeUserProjects({
  userId,
  projectIds,
}: RearrangeUserProjectsParams) {
  return axios.post(constructQueryPath(ENDPOINTS.USER_PROJECTS, { userId }), {
    action: 'rearrange_projects',
    project_ids: projectIds,
  });
}
