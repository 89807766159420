import { ReactNode, useState } from 'react';
import { Box } from '@mantine/core';

import BasePopOver from '../BasePopOver';
import PopOverDropdown from './components/PopOverDropdown';

import {
  ConfirmationPopOverDropdownProps,
  ConfirmationPopOverTargetProps,
} from './types';

interface ConfirmationPopOverProps {
  children?: ReactNode;
  onConfirm?: () => void;
  dropdownProps?: ConfirmationPopOverDropdownProps;
  DropdownComponent?: typeof PopOverDropdown;
}

export default function ConfirmationPopOver({
  children,
  onConfirm,
  dropdownProps,
  DropdownComponent,
}: ConfirmationPopOverProps) {
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <BasePopOver<
      ConfirmationPopOverDropdownProps,
      ConfirmationPopOverTargetProps
    >
      TargetComponent={Box}
      DropdownComponent={DropdownComponent}
      popoverProps={{
        opened: opened,
      }}
      dropdownProps={{
        onClose: () => setOpened(false),
        message: 'Unsubscribe User?',
        onConfirm,
        ...dropdownProps,
      }}
      targetProps={{
        children,
        onClick: () => setOpened(!opened),
      }}
      width={225}
    />
  );
}
