import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';

import { ENDPOINTS } from 'constants/api';
import type { ProjectTicket } from 'types/api';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

import type { TicketBody } from 'utils/ticket';
import * as ticketUtils from 'utils/ticket';
import * as projectUtils from 'utils/project';
import showApiErrNotif from 'helpers/api/showApiErrNotif';
import { ApiQueryKey } from 'types/api/query';
import useModalStore from 'hooks/store/useModalStore';
import { MODALS } from 'constants/component';

export interface ProjectTicketHookParams {
  projectId?: number | string;
  params?: any;
  storeKey?: ApiQueryKey;
  disabled?: boolean;
}

export default function useProjectTickets({
  projectId,
  params,
  storeKey,
  disabled,
}: ProjectTicketHookParams) {
  const navigate = useNavigate();
  const { invalidate } = useApiStore();
  const { pushModal } = useModalStore();

  const queryResult = useApiQuery<{ tickets?: ProjectTicket[] }>({
    path: ENDPOINTS.PROJECT_TICKETS,
    pathParams: {
      projectId: `${projectId}`,
    },
    queryOptions: {
      enabled: Boolean(projectId) && !disabled,
    },
    axiosConfig: {
      params,
    },
    storeKey,
  });

  const addProjectTicket = useCallback(
    async ({
      projectId,
      ticketBody,
    }: {
      projectId: string;
      ticketBody: Partial<TicketBody>;
    }) => {
      try {
        const res = await ticketUtils.addProjectTicket({
          projectId,
          ticketBody,
        });

        const ticket = res?.data?.ticket;

        if (ticket) {
          pushModal(MODALS.EDIT_TICKET_MODAL, { ticket });

          if (ticket.project?.id) {
            navigate(`/projects/${ticket.project?.id}?ticket_id=${ticket.id}`);
          }
        }

        invalidate('list-project-tickets');
        notifications.show({
          message: 'Ticket successfully added!',
          variant: 'success',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to add ticket.', err);
      }

      return false;
    },
    [invalidate, navigate, pushModal]
  );

  const rearrangeTickets = async (ticketIds: number[]) => {
    return projectUtils.rearrangeTickets({
      projectId: projectId as string,
      ticketIds,
    });
  };

  return {
    ...queryResult,
    addProjectTicket,
    rearrangeTickets,
  };
}
