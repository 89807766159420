import { useMemo } from 'react';

import { AUTH_USER_ID } from 'constants/api';
import useUser from './useUser';
import getInitials from 'helpers/display/getInitials';

export default function useAuthUser() {
  const { data, ...rest } = useUser({
    userId: AUTH_USER_ID,
    storeKey: 'auth-user',
    params: {
      embed: 'permissions',
    },
  });

  const user = useMemo(() => {
    return data?.user;
  }, [data]);

  return {
    user,
    initials: getInitials({
      firstName: user?.first_name,
      lastName: user?.last_name,
    }),
    ...rest,
  };
}
