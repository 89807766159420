import { Avatar } from '@mantine/core';

import { UserPopOverTargetProps } from '../types';

export default function PopOverTarget({ onClick }: UserPopOverTargetProps) {
  return (
    <Avatar radius="xl" onClick={onClick}>
      +
    </Avatar>
  );
}
