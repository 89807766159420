import { ApiQueryKey } from 'types/api/query';

export const ENDPOINTS = {
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  SSO_LOGIN: 'auth/sso-login',
  PROJECTS: 'projects',
  REPORTS: 'reports',
  REPORT: 'reports/:reportId',
  REPORT_ROWS: 'reports/:reportId/rows',
  BILLING_RECORDS: 'billing-records',
  BILLING_RECORD: 'billing-records/:billingRecordId',
  BILLING_RATES: 'billing-rates',
  BILLING_RATE_PREVIEW: 'billing-rates/preview',
  INVOICE_LINE_ITEMS: 'invoice-line-items',
  INVOICES: 'invoices',
  INVOICE: 'invoices/:invoiceId',
  INVOICE_PAYMENTS: 'invoices/:invoiceId/payments',
  PROJECT: 'projects/:projectId',
  PROJECT_TICKETS: 'projects/:projectId/tickets',
  PROJECT_ACTIVITY: 'projects/:projectId/activity',
  PROJECT_BILLING_RECORDS: 'projects/:projectId/billing-records',
  PROJECT_TICKET_BILLING_RECORDS:
    'projects/:projectId/tickets/:ticketId/billing-records',
  PROJECT_TICKET_STATUSES: 'projects/:projectId/ticket-statuses',
  MODULES: 'modules',
  CLIENTS: 'clients',
  CLIENT: 'clients/:clientId',
  USERS: 'users',
  USER_STATS: 'user-stats',
  USER_LABOR_COSTS: 'user-labor-costs',
  USER: 'users/:userId',
  USER_TICKETS: 'users/:userId/tickets',
  USER_PROJECTS: 'users/:userId/projects',
  USER_ACTIVITY: 'users/:userId/activity',
  USER_ALERTS: 'users/:userId/alerts',
  USER_ALERT: 'users/:userId/alerts/:alertId',
  TICKETS: 'tickets',
  TICKET_ASSIGNMENTS: 'tickets/assignments',
  TICKET_ASSIGNMENT: 'tickets/assignments/:assignmentId',
  TICKET: 'tickets/:ticketId',
  TICKET_COMMENTS: 'tickets/:ticketId/comments',
  TICKET_COMMENT: 'tickets/:ticketId/comments/:commentId',
  TICKET_ESTIMATES: 'tickets/:ticketId/estimates',
  TICKET_ESTIMATE: 'tickets/:ticketId/estimates/:estimateId',
  TICKET_BILLING_RECORDS: 'tickets/:ticketId/billing-records',
  TICKET_BILLING_RECORD: 'tickets/:ticketId/billing-records/:billingRecordId',
  TICKET_ACTIVITY: 'tickets/:ticketId/activity',
  JOB_TYPES: 'job-types',
  AVAILABILITY_RECORDS: 'availability-records',
  AVAILABILITY_RECORD: 'availability-records/:recordId',
  NOTIFICATIONS: 'notifications',
  NOTIFICATION: 'notifications/:notificationId',
  PING: 'ping',
  INSTANCE: '', // endpoint is implied
  INSTANCE_CONFIG: 'config',
  ACTIVITY: 'activity',
};

export const AUTH_USER_ID = 0;

export const INVALIDATE_GROUP = {
  // whenever a project is changed, force refetch in the following places:
  PROJECT: [
    'list-instance-activity',
    'list-projects',
    'list-projects-budget',
    'list-projects-embed',
  ] as ApiQueryKey[],

  // whenever the current auth user is changed, force refetch in the following places:
  SELF: ['auth-user'] as ApiQueryKey[],

  // whenever a user is changed, force refetch in the following places:
  USER: [
    'list-active-users',
    'list-inactive-users',
    'list-instance-activity',
  ] as ApiQueryKey[],

  // whenever a ticket is changed, force refetch in the following places:
  TICKET: [
    'edit-ticket-modal-activity',
    'list-project-activity-tab',
    'list-project-tickets',
    'list-user-projects',
    'edit-ticket-modal',
    'list-instance-activity',
    'list-tickets-modal',
  ] as ApiQueryKey[],

  // whenever a billing record is changed, force refetch in the following places:
  BILLING_RECORD: [
    'edit-ticket-modal',
    'edit-ticket-modal-activity',
    'list-project-billing-records-tab',
    'user-billing-records-timesheet',
    'list-billing-records-modal',
    'list-instance-activity',
    'missing-time',
    'user-alerts',
  ] as ApiQueryKey[],

  // whenever an expense is changed, force refetch in the following places:
  EXPENSE: [
    'edit-ticket-modal',
    'list-expenses-modal',
    'edit-ticket-modal-activity',
    'list-instance-activity',
  ] as ApiQueryKey[],
};
