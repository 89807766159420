import { notifications } from '@mantine/notifications';
import { ScrollArea, Stack } from '@mantine/core';
import { AxiosError } from 'axios';

import DetailsSection from './DetailsSection';
import EstimatesSection from './EstimatesSection';
import BillingSection from './BillingSection';
import PeopleSection from './PeopleSection';

import { TicketBody, updateTicket } from 'utils/ticket';
import {
  AddTicketEstimateCallback,
  DeleteTicketEstimateCallback,
  EditTicketEstimateCallback,
  UpdateTicketSubscriptionCallback,
} from '../types';
import { ProjectTicket } from 'types/api';
import { INVALIDATE_GROUP } from 'constants/api';

import useApiStore from 'hooks/store/useApiStore';

interface EditTicketModalSideContentProps {
  ticket?: ProjectTicket;
  addTicketEstimate?: AddTicketEstimateCallback;
  editTicketEstimate?: EditTicketEstimateCallback;
  deleteTicketEstimate?: DeleteTicketEstimateCallback;
  updateTicketSubscription?: UpdateTicketSubscriptionCallback;
}

export default function EditTicketModalSideContent({
  ticket,
  addTicketEstimate,
  editTicketEstimate,
  deleteTicketEstimate,
  updateTicketSubscription,
}: EditTicketModalSideContentProps) {
  const { invalidate, invalidateGroup, invalidatePath } = useApiStore();

  const handleTicketUpdate = async (ticketBody: Partial<TicketBody>) => {
    const refreshPageKeys: (keyof TicketBody)[] = ['status'];

    try {
      await updateTicket(ticket?.id as number, ticketBody);
      invalidateGroup(
        INVALIDATE_GROUP.TICKET.filter(
          (group) => group !== 'list-project-tickets'
        )
      );
      invalidatePath(['projects', '', 'tickets']);
      let hasRefetchedPage = false;
      refreshPageKeys.forEach((key) => {
        if (ticketBody[key] && !hasRefetchedPage) {
          hasRefetchedPage = true;
          invalidate('list-project-tickets');
        }
      });

      notifications.show({
        message: 'Ticket successfully updated!',
        color: 'green',
      });
      return true;
    } catch (err) {
      const error = err as AxiosError;
      notifications.show({
        message: error.message,
        color: 'red',
      });
    }

    return false;
  };

  return (
    <ScrollArea
      sx={(theme) => ({
        backgroundColor: theme.black,
        color: theme.white,
        padding: theme.spacing.sm,
        width: '100%',
        height: '100%',
      })}
    >
      <Stack>
        <DetailsSection
          ticket={ticket}
          handleTicketUpdate={handleTicketUpdate}
        />
        <EstimatesSection
          ticket={ticket}
          estimates={ticket?.estimates || []}
          addTicketEstimate={addTicketEstimate}
          deleteTicketEstimate={deleteTicketEstimate}
          editTicketEstimate={editTicketEstimate}
        />
        <BillingSection
          ticket={ticket}
          handleTicketUpdate={handleTicketUpdate}
        />
        <PeopleSection
          ticket={ticket}
          handleTicketUpdate={handleTicketUpdate}
          updateTicketSubscription={updateTicketSubscription}
        />
      </Stack>
    </ScrollArea>
  );
}
