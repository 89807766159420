import { useState, useCallback, useEffect } from 'react';
import {
  UnstyledButton,
  Menu,
  Box,
  Text,
  ScrollArea,
  Stack,
} from '@mantine/core';

import SelectItem from './SelectItem';
import SelectTarget from './SelectTarget';

import { SelectOption, SelectProps } from '../../types';

import useSelect from 'hooks/common/useSelect';

export default function BaseSelect({
  options,
  onChange,
  label,
  width = 'target',
  TargetComponent = SelectTarget,
  defaultValue,
  defaultFirst,
  sx,
  required,
  readonly,
  includeEmpty,
  error,
  highlight,
}: SelectProps) {
  const emptyOption = { label: '', value: '' } as SelectOption;
  const updatedOptions = includeEmpty
    ? [emptyOption].concat(options || [])
    : options;
  const [valueSet, setValueSet] = useState(false);
  const [opened, setOpened] = useState(false);
  const { selected, setSelected } = useSelect({
    defaultValue,
    options: updatedOptions as SelectOption[],
  });

  const handleChange = useCallback(
    (option: SelectOption) => {
      setSelected(option);
      onChange?.(option);
    },
    [onChange, setSelected]
  );

  useEffect(() => {
    if (
      defaultFirst &&
      updatedOptions?.length &&
      onChange &&
      defaultValue === undefined &&
      !valueSet
    ) {
      setValueSet(true);
      onChange(updatedOptions[0]);
    }
  }, [defaultFirst, updatedOptions, onChange, defaultValue, valueSet]);

  return (
    <Box sx={{ width: '100%', marginTop: 'auto', marginBottom: 'auto', ...sx }}>
      {Boolean(label) && (
        <Text
          sx={(theme) => ({ fontSize: theme.fontSizes.sm, lineHeight: '175%' })}
        >
          {label}{' '}
          {required && (
            <Text component="span" color="red">
              *
            </Text>
          )}
        </Text>
      )}
      {readonly ? (
        <TargetComponent
          selected={selected as SelectOption}
          opened={opened}
          readonly={readonly}
          highlight={highlight}
        />
      ) : (
        <Menu
          onOpen={() => setOpened(true)}
          onClose={() => setOpened(false)}
          radius="md"
          width={width}
          withinPortal
        >
          <Menu.Target>
            <UnstyledButton sx={{ width: '100%' }}>
              <TargetComponent
                selected={selected as SelectOption}
                opened={opened}
                highlight={highlight}
              />
              {error && (
                <Text component="span" color="red" size="xs">
                  {error}
                </Text>
              )}
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            <ScrollArea>
              <Stack spacing={1} sx={{ maxHeight: '25vh' }}>
                {updatedOptions?.map((option) => (
                  <SelectItem
                    key={option.value}
                    option={option}
                    setSelected={handleChange}
                  />
                ))}
              </Stack>
            </ScrollArea>
          </Menu.Dropdown>
        </Menu>
      )}
    </Box>
  );
}
