import CommentsContainer from './variants/CommentsContainer';

interface CommentsProps {
  variant?: 'item' | 'list';
  ticketId?: string;
  highlight?: boolean;
}

export default function Comments({ ticketId, highlight }: CommentsProps) {
  return (
    <CommentsContainer ticketId={ticketId as string} highlight={highlight} />
  );
}
