import { ProjectTicket } from 'types/api';
import { TicketBody } from 'utils/ticket';

import ContentSection from '../ContentSection';

import SubscribersGroup from './SubscribersGroup';
import { UpdateTicketSubscriptionCallback } from '../../types';

interface PeopleSectionProps {
  ticket?: ProjectTicket;
  handleTicketUpdate: (ticketBody: Partial<TicketBody>) => Promise<boolean>;
  updateTicketSubscription?: UpdateTicketSubscriptionCallback;
}

export default function PeopleSection({
  ticket,
  updateTicketSubscription,
}: PeopleSectionProps) {
  return (
    <ContentSection title="People">
      <SubscribersGroup
        ticket={ticket}
        subscribers={ticket?.subscribed_users}
        updateTicketSubscription={updateTicketSubscription}
      />
    </ContentSection>
  );
}
