import { Group, Avatar } from '@mantine/core';
import { LiteTicketUser, ProjectTicket } from 'types/api';

import ConfirmationPopOver from 'components/common/PopOver/variants/ConfirmationPopOver';

import getInitials from 'helpers/display/getInitials';
import getUserFullName from 'helpers/display/getUserFullName';
import { UpdateTicketSubscriptionCallback } from '../../types';
import UserPopOver from 'components/common/PopOver/variants/UserPopOver';

interface SubscribersGroupProps {
  ticket?: ProjectTicket;
  subscribers?: LiteTicketUser[];
  updateTicketSubscription?: UpdateTicketSubscriptionCallback;
}

interface SubscriberUserProps {
  subscriber: LiteTicketUser;
  onConfirm?: () => void;
}

function SubscriberUser({ subscriber, onConfirm }: SubscriberUserProps) {
  const fullName = getUserFullName(subscriber);
  const initials = getInitials({ fullName });

  return (
    <ConfirmationPopOver onConfirm={onConfirm}>
      <Avatar title="Unsubscribe" radius="xl">
        {initials}
      </Avatar>
    </ConfirmationPopOver>
  );
}

export default function SubscribersGroup({
  ticket,
  subscribers = [],
  updateTicketSubscription,
}: SubscribersGroupProps) {
  const handleFilter = (userId: string) => {
    return !ticket?.subscribed_users?.find(
      (user: LiteTicketUser) => user.id?.toString() === userId.toString()
    );
  };

  return (
    <Group>
      {subscribers.map((subscriber) => (
        <SubscriberUser
          key={subscriber.id}
          subscriber={subscriber}
          onConfirm={() =>
            updateTicketSubscription?.(
              `${ticket?.id}`,
              `${subscriber.id}`,
              false
            )
          }
        />
      ))}
      <UserPopOver
        updateUserSubscription={async (userId: string) => {
          updateTicketSubscription?.(`${ticket?.id}`, userId, true);
        }}
        filter={handleFilter}
      />
    </Group>
  );
}
