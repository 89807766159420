import { useState } from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { Text, Grid, ActionIcon, Box, Skeleton } from '@mantine/core';
import {
  IconTrash,
  IconCircleX,
  IconCircleCheck,
  IconEdit,
} from '@tabler/icons-react';

import { EditableRowProps } from '../types';
import { DATE_FORMATS } from 'constants/date';

import DatePopOver from 'components/common/PopOver/variants/DatePopOver';
import EditableItem from './EditableItem';

interface DeletingContentProps {
  billingRecord: EditableRowProps['billingRecord'];
  deleteBillingRecord: EditableRowProps['deleteBillingRecord'];
  setIsDeleting: (isDeleting: boolean) => void;
}

function DeletingContent({
  billingRecord,
  deleteBillingRecord,
  setIsDeleting,
}: DeletingContentProps) {
  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        paddingLeft: theme.spacing.xs,
      })}
    >
      <Text
        sx={(theme) => ({
          marginTop: 'auto',
          marginBottom: 'auto',
          color: theme.colors.red[9],
          lineHeight: '100%',
        })}
      >
        Are you sure you want to delete this billing record?
      </Text>
      <Box sx={{ display: 'flex' }}>
        <ActionIcon
          color="blue"
          sx={{ marginTop: 'auto', marginBottom: 'auto' }}
          onClick={() =>
            deleteBillingRecord({
              ticketId: billingRecord.ticket_id as number,
              billingRecordId: billingRecord.id as number,
            })
          }
        >
          <IconCircleCheck />
        </ActionIcon>
        <ActionIcon
          color="red"
          sx={{ marginTop: 'auto', marginBottom: 'auto' }}
          onClick={() => setIsDeleting(false)}
        >
          <IconCircleX />
        </ActionIcon>
      </Box>
    </Box>
  );
}

interface EditableContentProps {
  billingRecord: EditableRowProps['billingRecord'];
  editBillingRecord: EditableRowProps['editBillingRecord'];
  variant: EditableRowProps['variant'];
  fields: EditableRowProps['fields'];
  setIsDeleting: (isDeleting: boolean) => void;
  setIsEditing: (isEditing: boolean) => void;
  readonly?: boolean;
  toggle?: boolean;
  isInfinite?: boolean;
}

function EditableContent({
  billingRecord,
  editBillingRecord,
  variant,
  fields = [],
  setIsDeleting,
  setIsEditing,
  readonly,
  toggle,
}: EditableContentProps) {
  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          paddingRight: theme.spacing.lg,
        })}
      >
        <DatePopOver
          defaultValue={parse(
            billingRecord.date,
            DATE_FORMATS.DATE_KEY,
            new Date()
          )}
          onChange={async (date) => {
            editBillingRecord({
              ticketId: billingRecord.ticket_id,
              billingRecordId: billingRecord?.id as number,
              body: {
                date: format(date, DATE_FORMATS.DATE_KEY),
              },
            });
          }}
        />
        <EditableItem
          billingRecord={billingRecord}
          recordKey="employee_id"
          editBillingRecord={editBillingRecord}
          variant={variant}
          readonly={readonly && toggle}
        />
      </Box>
      <Grid
        columns={fields.length * 2 - 1}
        sx={() => ({
          flexGrow: 1,
        })}
      >
        {fields?.includes('ticket') && (
          <Grid.Col xs={2} sx={{ display: 'flex' }}>
            <EditableItem
              billingRecord={billingRecord}
              recordKey="ticket"
              editBillingRecord={editBillingRecord}
              variant={variant}
              readonly={readonly && toggle}
            />
          </Grid.Col>
        )}
        {fields?.includes('amount') && (
          <Grid.Col xs={1} sx={{ display: 'flex' }}>
            <EditableItem
              billingRecord={billingRecord}
              recordKey="amount"
              editBillingRecord={editBillingRecord}
              variant={variant}
              readonly={readonly && toggle}
            />
          </Grid.Col>
        )}
        {fields?.includes('description') && (
          <Grid.Col xs={2} sx={{ display: 'flex' }}>
            <EditableItem
              billingRecord={billingRecord}
              recordKey="description"
              editBillingRecord={editBillingRecord}
              variant={variant}
              readonly={readonly && toggle}
            />
          </Grid.Col>
        )}
        {fields?.includes('job_type') && (
          <Grid.Col xs={2} sx={{ display: 'flex' }}>
            <EditableItem
              billingRecord={billingRecord}
              recordKey="job_type"
              editBillingRecord={editBillingRecord}
              variant={variant}
              readonly={readonly && toggle}
            />
          </Grid.Col>
        )}
      </Grid>
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'flex-end',
          paddingLeft: theme.spacing.lg,
        })}
      >
        {toggle && (
          <ActionIcon
            title="Edit"
            color={readonly ? 'gray' : 'blue'}
            sx={{ marginTop: 'auto', marginBottom: 'auto' }}
            onClick={() => setIsEditing(Boolean(readonly && toggle))}
          >
            <IconEdit />
          </ActionIcon>
        )}
        <EditableItem
          billingRecord={billingRecord}
          recordKey="billable"
          editBillingRecord={editBillingRecord}
          variant={variant}
          readonly={
            (readonly && toggle) ||
            Boolean(
              !billingRecord.ticket?.billable ||
                !billingRecord.ticket.project?.billable
            )
          }
        />
        <ActionIcon
          title="Delete"
          color="red"
          sx={{ marginTop: 'auto', marginBottom: 'auto' }}
          onClick={() => setIsDeleting(true)}
        >
          <IconTrash />
        </ActionIcon>
      </Box>
    </>
  );
}

export default function EditableRow({
  billingRecord,
  editBillingRecord,
  deleteBillingRecord,
  variant,
  loading,
  style,
  fields = ['amount', 'description', 'job_type'],
  toggle,
}: EditableRowProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        marginTop: theme.spacing.xs,
        marginBottom: theme.spacing.xs,
        backgroundColor: isDeleting ? theme.colors.red[0] : undefined,
        width: '100%',
      })}
      style={style}
    >
      {loading || !billingRecord ? <Skeleton sx={{ height: '40px' }} /> : <></>}
      {!loading && isDeleting && (
        <DeletingContent
          billingRecord={billingRecord}
          deleteBillingRecord={deleteBillingRecord}
          setIsDeleting={setIsDeleting}
        />
      )}
      {!loading && !isDeleting && (
        <EditableContent
          billingRecord={billingRecord}
          editBillingRecord={editBillingRecord}
          variant={variant}
          fields={fields}
          setIsDeleting={setIsDeleting}
          readonly={!isEditing}
          setIsEditing={setIsEditing}
          toggle={toggle}
        />
      )}
    </Box>
  );
}
