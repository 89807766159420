import { create } from 'zustand';

interface AppStoreState {
  lastTimeType: string;
  setLastTimeType: (billingType: string) => void;
  lastExpenseType: string;
  setLastExpenseType: (expenseType: string) => void;
}

const useAppStore = create<AppStoreState>((set) => ({
  lastTimeType: '',
  lastExpenseType: '',
  setLastTimeType: (timeType: string) => {
    set((state) => ({
      ...state,
      lastTimeType: timeType,
    }));
  },
  setLastExpenseType: (expenseType: string) => {
    set((state) => ({
      ...state,
      lastExpenseType: expenseType,
    }));
  },
}));

export default useAppStore;
