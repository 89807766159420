import { useMemo, MouseEvent } from 'react';
import {
  Modal as MantineModal,
  Loader,
  Box,
  useMantineTheme,
  getBreakpointValue,
  em,
  ScrollArea,
  Overlay,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { ModalProps } from '../../types';
import { MODAL_HEADER_HEIGHT } from 'constants/component';

import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';

import useModalStore from 'hooks/store/useModalStore';

export default function BaseModal({
  id,
  children,
  isOpen,
  onClose,
  title,
  HeaderComponent = ModalHeader,
  BodyComponent = ModalBody,
  FooterComponent = undefined,
  hideHeader,
  size = '60vw',
  height = '90vh',
  loading = false,
  includesBackdrop = true,
  contentOverlayProps,
  scrollable = true,
  preventBackdropClose,
}: ModalProps) {
  const { peekStack, modalStack } = useModalStore();
  const isUnder = modalStack.includes(id) && peekStack() !== id;
  const _includesBackdrop = includesBackdrop && modalStack[0] === id;
  const theme = useMantineTheme();
  const isLg = useMediaQuery(
    `(min-width: ${em(getBreakpointValue(theme.breakpoints.lg) + 1)})`
  );
  const isSm = useMediaQuery(
    `(max-width: ${em(getBreakpointValue(theme.breakpoints.sm))})`
  );

  const modalSize = useMemo(() => {
    if (isLg) {
      return size;
    }

    if (isSm) {
      return '100vw';
    }

    return '80vw';
  }, [isLg, isSm, size]);

  const handleClose = (e?: MouseEvent) => {
    if (e) {
      e.stopPropagation();
    }

    onClose?.();
  };

  const _contentOverlayProps = useMemo(() => {
    // blur overlay if this is not top of stack
    if (!contentOverlayProps && isUnder) {
      return {
        blur: 2,
      };
    }

    return contentOverlayProps;
  }, [contentOverlayProps, isUnder]);

  const showContentOverlay = Boolean(_contentOverlayProps);

  return (
    <MantineModal.Root
      size={modalSize}
      opened={Boolean(isOpen)}
      onClose={preventBackdropClose ? () => {} : handleClose}
      scrollAreaComponent={scrollable ? ScrollArea : undefined}
      sx={() => ({
        overflow: 'hidden',
      })}
    >
      <MantineModal.Overlay
        opacity={_includesBackdrop ? undefined : 0}
        onClick={(e) => (preventBackdropClose ? {} : handleClose(e))}
        blur={isUnder ? 3 : undefined}
      />
      <MantineModal.Content
        sx={{ overflow: scrollable ? undefined : 'hidden' }}
      >
        {!hideHeader && <HeaderComponent title={title} onClose={handleClose} />}
        <BodyComponent height={`calc(${height} - ${MODAL_HEADER_HEIGHT})`}>
          {showContentOverlay && <Overlay center {..._contentOverlayProps} />}
          {loading ? (
            <Box sx={{ height: '100%', width: '100%', display: 'flex' }}>
              <Loader size="xl" sx={{ margin: 'auto' }} />
            </Box>
          ) : (
            children
          )}
        </BodyComponent>
        {FooterComponent && <FooterComponent />}
      </MantineModal.Content>
    </MantineModal.Root>
  );
}
