import { useCallback } from 'react';
import { notifications } from '@mantine/notifications';

import { ENDPOINTS, INVALIDATE_GROUP } from 'constants/api';
import * as billing from 'utils/billing';

import useApiQuery from 'hooks/api/useApiQuery';
import useApiStore from 'hooks/store/useApiStore';

import { BillingRecordsHookParams } from './types';
import type { BillingRecord } from 'types/api';
import showApiErrNotif from 'helpers/api/showApiErrNotif';

export default function useBillingRecords(
  hookParams?: BillingRecordsHookParams,
  disabled?: boolean
) {
  const { params, storeKey } = hookParams || {};
  const { invalidate, invalidateGroup, invalidatePath } = useApiStore();

  const queryResult = useApiQuery<{ billing_records?: BillingRecord[] }>({
    path: ENDPOINTS.BILLING_RECORDS,
    axiosConfig: {
      params,
    },
    queryOptions: {
      enabled: !disabled,
    },
    storeKey: storeKey,
  });

  const addBillingRecord: typeof billing.addBillingRecord = useCallback(
    async (addBillingRecordParams) => {
      try {
        await billing.addBillingRecord(addBillingRecordParams);
        invalidateGroup(INVALIDATE_GROUP.BILLING_RECORD);
        invalidatePath(['activity']);
        invalidate('list-project-tickets');
        queryResult.refetch();
        notifications.show({
          message: 'Billing Record has been successfully added!',
          variant: 'success',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to add billing record. Try again later', err);
      }

      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invalidateGroup, invalidatePath, queryResult]
  );

  const addExpense: typeof billing.addBillingRecord = useCallback(
    async (addBillingRecordParams) => {
      try {
        await billing.addBillingRecord(addBillingRecordParams);
        invalidateGroup(INVALIDATE_GROUP.EXPENSE);
        invalidatePath(['activity']);
        notifications.show({
          message: 'Expense successfully added!',
          color: 'green',
        });
        return true;
      } catch (err) {
        showApiErrNotif('Unable to add expense. Try again later.', err);
      }

      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invalidateGroup, invalidatePath, queryResult]
  );

  const editBillingRecord: typeof billing.editBillingRecord = useCallback(
    async (editBillingRecordParams) => {
      try {
        await billing.editBillingRecord(editBillingRecordParams);
        invalidate('user-alerts');
        queryResult.refetch();
        notifications.show({
          message: 'Billing Record has been successfully updated!',
          variant: 'success',
        });
      } catch (err) {
        showApiErrNotif(
          'Unable to update billing record. Try again later.',
          err
        );
      }
    },
    [invalidate, queryResult]
  );

  const deleteBillingRecord = useCallback(
    async (deleteBillingRecordParams) => {
      try {
        const res = await billing.deleteBillingRecord(
          deleteBillingRecordParams
        );
        invalidate('edit-ticket-modal');
        invalidate('edit-ticket-modal-activity');
        queryResult.refetch();
        notifications.show({
          message: 'Billing Record has been successfully deleted!',
          variant: 'success',
        });
        return res;
      } catch (err) {
        showApiErrNotif(
          'Unable to delete billing record. Try again later.',
          err
        );
      }
    },
    [invalidate, queryResult]
  ) as typeof billing.deleteBillingRecord;

  return {
    ...queryResult,
    editBillingRecord,
    deleteBillingRecord,
    addBillingRecord,
    addExpense,
  };
}
