import { Stack, ScrollArea, Box } from '@mantine/core';

import AddCommentItem from '../AddCommentItem';
import CommentItem from '../CommentItem';

import useTicketComments from 'hooks/tickets/useTicketComments';

interface CommentsContainerProps {
  ticketId: string;
  scrollable?: boolean;
  highlight?: boolean;
}

export default function CommentsContainer({
  ticketId,
  scrollable = true,
  highlight,
}: CommentsContainerProps) {
  const { data, addTicketComment, isLoading } = useTicketComments({ ticketId });
  const Container = scrollable ? ScrollArea : Box;

  return (
    <>
      <Stack spacing={4} sx={{ overflow: 'hidden', height: '100%' }}>
        <AddCommentItem
          onSubmit={addTicketComment}
          loading={isLoading}
          highlight={highlight}
        />
        <Container offsetScrollbars>
          <Stack spacing={0}>
            {data?.comments?.map((comment) => (
              <CommentItem key={comment.id} comment={comment} />
            ))}
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
